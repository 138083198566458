<template>
  <!-- component -->
  <div
    class="min-w-screen min-h-screen bg-white flex flex-col justify-center overflow-hidden relative"
  >
    <div class="mx-auto mb-5 relative">
      <img :src="StarLeft" class="top-10 -left-22 absolute" />
      <img :src="StarRight" class="top-0 -right-18 absolute" />
      <img :src="IconSucces" />
      <div class="text-hgray7 text-xl font-semibold mt-8 text-center">Congratulation</div>
    </div>
    <div class="min-h-full w-full bg-hblue5 p-10 relative items-center text-center">
      <p class="text-2xl font-medium text-white">User has been</p>
      <p class="text-4xl font-semibold text-hwarning2 mt-3">Successfully Activated</p>
    </div>

    <div class="mx-auto mt-5">
      <img :src="ImageBottom" />
    </div>
  </div>
</template>

<script>
import StarLeft from '@/assets/images/iconStarLeft.png'
import StarRight from '@/assets/images/iconStarRight.png'
import IconSucces from '@/assets/images/iconSucces.png'
import ImageBottom from '@/assets/images/sabasysmex.png'

export default {
  name: 'error',
  data() {
    return {
      StarLeft,
      StarRight,
      IconSucces,
      ImageBottom
    }
  }
}
</script>
